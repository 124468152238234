@import "../../scss/global/variables";

footer.footer {
  padding: 0 12px;
  box-sizing: border-box;
  border-top: 2px solid $brand-secondary-color;

  .listGroupItemTertiary {
    font-size: 14px;
    color: $color-grey;
    background-color: transparent;

    &:hover {
      color: $brand-primary-color;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.ecoFriendly {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('https://cdn.wholesalepackaging.co.uk/images/backgrounds/eco_friendly.webp');
}

.feedback {
  position: fixed;
  z-index: 1000;
  bottom: 100px;
  right: -48px;
  padding-bottom: 10px;
  transform: rotate(-90deg);
}

body.no-webp {
  .ecoFriendly {
    background-image: url('https://cdn.wholesalepackaging.co.uk/images/backgrounds/eco_friendly.jpg');
  }
}
