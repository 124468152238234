@import 'colours';

$font-family: 'Roboto', sans-serif;

$brand-primary-color: #027d96;
$brand-secondary-color: #178da4;
$brand-tertiary-color: #a7ced7;
$brand-danger-color: #dc3545;
$brand-success-color: #4CAF50;
$brand-warning-color: #ffc107;
$brand-info-color: #17a2b8;
$brand-light-color: #f8f9fa;
$brand-dark-color: #333333;

$color-white: #ffffff;
$color-black: #000000;
$color-dark-grey: #222222;
$color-dark-grey-alt: #444444;
$color-grey: #9e9e9e;
$color-grey-light: #C9C9C9;
$color-grey-light-3: #eeeeee;
$color-green: #4CAF50;

//BREAKPOINTS
$tablet: 768px;
$desktop: 998px;
$largeDesktop: 1366px;
